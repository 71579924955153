import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const Twitter = ({ children, className, ...rest }) => (
  <a {...rest} href="https://twitter.com/jcbaey" className={`${className}`} >
    <span className="icon">
      <i className="fa fa-twitter mr-1" />
    </span>
    {children || 'Twitter'}
  </a>
);

Twitter.propTypes = {
  children: PropTypes.object,
};

export class TwitterFollowButton extends PureComponent {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
  }

  /**
   * Forces the reload of the twitter javascript defined in html.js
   */
  componentDidMount() {
    // eslint-disable-next-line no-undef
    if (typeof twttr.widgets !== 'undefined') {
      const node = this.buttonRef.current;
      // eslint-disable-next-line no-undef
      twttr.widgets.load(node.parentNode);
    }
  }

  render() {
    const { account = 'jcbaey', className='', ...others } = this.props;
    return (
      <a
        ref={this.buttonRef}
        className={`twitter-follow-button ${className}`}
        href={`https://twitter.com/${account}`}
        data-show-count="false"
        {...others}
      >
        {`Follow @${account}`}
      </a>
    );
  }
}
