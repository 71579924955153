import React from "react";
import PropTypes from "prop-types";

export const Email = ({ children, icon, className, ...rest }) => (
  <a {...rest} href="mailto:jcbaey@gmail.com" className={`${className}`} >
    {icon && (
      <span className="icon">
        <i className="fa fa-envelope mr-1" />
      </span>
    )}
    {children || "jcbaey@gmail.com"}
  </a>
);

Email.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
};
