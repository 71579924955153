import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Location } from '@reach/router';

export const NavLink = ({ label, to = '/' }) => (
  <Location>
    {({ location }) => {
      const re = new RegExp(to, 'i');
      const isSelected = re.test(location.pathname);
      const target = to
        .replace(/\$/g, '')
        .replace(/\.*/g, '')
        .replace(/\.+/g, '');
      return (
        <Link to={target} className="no-underline text-gray-900">
          <div
            className={`mb-1 p-3 rounded-lg  ${
              isSelected ? 'bg-gray-400 hover:bg-gray-500 font-bold' : 'hover:bg-gray-500'
            }`}
          >
            {label}
          </div>
        </Link>
      );
    }}
  </Location>
);

export const HamburgerButton = ({ onClick, selected }) => (
  <button
    type="button"
    className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-teal-500 appearance-none focus:outline-none"
    onClick={onClick}
  >
    <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <title>Menu</title>
      {selected ? (
        <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
      ) : (
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
      )}
    </svg>
  </button>
);

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `rebeccapurple`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
