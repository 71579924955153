import React from 'react';
import PropTypes from 'prop-types';

export const Github = ({ children, className, ...rest }) => (
  <a {...rest} href="https://github.com/jcbaey" className={`${className}`}>
    <span className="icon">
      <i className="fa fa-github mr-2" />
    </span>

    {children || 'Github'}
  </a>
);

Github.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
