import React from 'react';
import PropTypes from 'prop-types';

export const LinkedIn = ({ children, className, ...rest }) => (
  <a {...rest} href="https://www.linkedin.com/in/jcbaey" className={`${className}`}>
    <span className="icon">
      <i className="fa fa-linkedin mr-2" />
    </span>

    {children || 'LinkedIn'}
  </a>
);

LinkedIn.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
